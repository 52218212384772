// ApiKeys.js
import React, { useState, useEffect } from 'react';
import api from '../api';

const ApiKeys = () => {
  const [apiKey, setApiKey] = useState('');
  const [apiSecret, setApiSecret] = useState('');
  const [exchange, setExchange] = useState('bybit');
  const [isKeyVisible, setIsKeyVisible] = useState(true);
  const [keyExists, setKeyExists] = useState(false);

  useEffect(() => {
    const checkApiKeys = async () => {
      try {
        const response = await api.post('/auth/api-keys/check', { exchange });
        setKeyExists(response.data.exists);
        setIsKeyVisible(!response.data.exists);
      } catch (error) {
        console.error('Error checking API keys:', error);
      }
    };

    checkApiKeys();
  }, [exchange]);

  const handleSave = async () => {
    try {
      await api.post('/auth/api-keys', {
        exchange,
        api_key: apiKey,
        api_secret: apiSecret
      });
      alert('API keys saved successfully');
      setIsKeyVisible(false);
      setKeyExists(true);
    } catch (error) {
      console.error('Error saving API keys:', error);
      alert('API keys already exist');
    }
  };

  const handleDelete = async () => {
    try {
      await api.delete('/auth/api-keys', {
        data: { exchange }
      });
      setApiKey('');
      setApiSecret('');
      alert('API keys deleted successfully');
      setIsKeyVisible(true);
      setKeyExists(false);
    } catch (error) {
      console.error('Error deleting API keys:', error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900 text-white">
      <div className="w-full max-w-xs p-6 bg-gray-800 rounded-lg shadow-md">
        <h1 className="text-2xl font-bold mb-4">Enter Your API Keys</h1>
        {isKeyVisible ? (
          <>
            <div className="flex space-x-2 mb-4">
              <button
                className={`py-2 px-4 rounded ${exchange === 'bybit' ? 'bg-blue-700' : 'bg-blue-500 hover:bg-blue-700'}`}
                onClick={() => setExchange('bybit')}
              >
                Bybit
              </button>
              {/* Add more buttons for other exchanges here */}
            </div>
            <input
              className="w-full p-2 mb-4 text-black rounded"
              type="text"
              placeholder="API Key"
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
            />
            <input
              className="w-full p-2 mb-4 text-black rounded"
              type="text"
              placeholder="API Secret"
              value={apiSecret}
              onChange={(e) => setApiSecret(e.target.value)}
            />
            <button
              className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={handleSave}
            >
              Save
            </button>
          </>
        ) : keyExists ? (
          <>
            <p className="mb-4">API Key: <span className="bg-gray-700 p-1 rounded">*********</span></p>
            <p className="mb-4">API Secret: <span className="bg-gray-700 p-1 rounded">*********</span></p>
            <button
              className="w-full bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              onClick={handleDelete}
            >
              Delete Keys
            </button>
          </>
        ) : (
          <p className="mb-4">No API keys saved for {exchange}.</p>
        )}
      </div>
    </div>
  );
};

export default ApiKeys;
