// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import WebhookDashboard from './components/WebhookDashboard';
import ApiKeys from './components/ApiKeys';
import TradingViewLog from './components/TradingViewLog';
import LandingPage from './components/LandingPage';
import Navigation from './components/Navigation';
import api from './api'; // Import the configured Axios instance

const App = () => {
  const [token, setToken] = useState(null);
  const [apiKeysSet, setApiKeysSet] = useState(false);

  useEffect(() => {
    const savedToken = localStorage.getItem('token');
    if (savedToken) {
      setToken(savedToken);
    }
  }, []);

  useEffect(() => {
    if (token) {
      const checkApiKeys = async () => {
        try {
          const response = await api.post('/auth/api-keys/check', {
            exchange: 'bybit'
          });
          setApiKeysSet(response.data.exists);
        } catch (error) {
          console.error('Error checking API keys:', error);
        }
      };

      checkApiKeys();
    }
  }, [token]);

  return (
    <Router>
      <Navigation token={token} setToken={setToken} />
      <div className="container mx-auto p-4">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login setToken={setToken} />} />
          <Route path="/register" element={<Register />} />
          <Route path="/api-keys" element={<ApiKeys token={token} />} />
          <Route path="/dashboard" element={<WebhookDashboard token={token} />} />
          <Route path="/logs" element={<TradingViewLog token={token} />} />
          <Route path="/syntax-generator" element={<WebhookDashboard />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
