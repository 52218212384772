//SyntaxGenerator.js
import React, { useState } from 'react';

const SyntaxGenerator = ({ exchange }) => {
  const [mode, setMode] = useState(null);
  const [symbol, setSymbol] = useState('');
  const [quantity, setQuantity] = useState('');
  const [generatedSyntax, setGeneratedSyntax] = useState('');

  const handleGenerateSyntax = () => {
    let syntax = {};
    if (mode === 'tvStrategy') {
      syntax = {
        action: "{{strategy.order.action}}",
        quantity: "{{strategy.position_size}}",
        symbol,
        closeprevious: "true"
      };
    } else if (mode === 'stopLoss') {
      syntax = {
        action: "SL",
        symbol
      };
    } else if (mode === 'takeProfit') {
      syntax = {
        action: "TP",
        symbol,
        quantity
      };
    }
    setGeneratedSyntax(JSON.stringify(syntax, null, 2));
  };

  const resetFields = () => {
    setSymbol('');
    setQuantity('');
    setGeneratedSyntax('');
  };

  const handleModeSelect = (selectedMode) => {
    setMode(selectedMode);
    resetFields();
  };

  return (
    <div className="w-full px-2 mb-4">
      <div className="w-full px-2 mb-4">
        <h2 className="text-xl font-bold mb-2">Mode</h2>
        <div className="flex space-x-2">
          <button
            className={`py-2 px-4 rounded ${mode === 'tvStrategy' ? 'bg-purple-700' : 'bg-purple-500 hover:bg-purple-700'}`}
            onClick={() => handleModeSelect('tvStrategy')}
          >
            TV Strategy
          </button>
          <button
            className={`py-2 px-4 rounded ${mode === 'stopLoss' ? 'bg-red-700' : 'bg-red-500 hover:bg-red-700'}`}
            onClick={() => handleModeSelect('stopLoss')}
          >
            Stop Loss
          </button>
          <button
            className={`py-2 px-4 rounded ${mode === 'takeProfit' ? 'bg-green-700' : 'bg-green-500 hover:bg-green-700'}`}
            onClick={() => handleModeSelect('takeProfit')}
          >
            Take Profit
          </button>
        </div>
      </div>
      {mode && (
        <>
          <div className="w-full px-2 mb-4">
            <h2 className="text-xl font-bold mb-2">Symbol</h2>
            <input
              className="w-full p-2 mb-4 text-black rounded"
              type="text"
              placeholder="e.g. BTCUSDT"
              value={symbol}
              onChange={(e) => setSymbol(e.target.value)}
            />
          </div>
          {(mode === 'tvStrategy' || mode === 'takeProfit') && symbol && (
            <div className="w-full px-2 mb-4">
              <h2 className="text-xl font-bold mb-2">Quantity</h2>
              <input
                className="w-full p-2 mb-4 text-black rounded"
                type="text"
                placeholder="e.g. 1"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
              />
            </div>
          )}
          {symbol && (mode === 'stopLoss' || quantity) && (
            <div className="w-full px-2 mb-4">
              <button
                className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={handleGenerateSyntax}
              >
                Generate Syntax
              </button>
            </div>
          )}
        </>
      )}
      {generatedSyntax && (
        <div className="w-full px-2">
          <h2 className="text-xl font-bold mb-2">Generated Syntax</h2>
          <pre className="bg-gray-800 p-4 rounded">{generatedSyntax}</pre>
          <button
            className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
            onClick={() => navigator.clipboard.writeText(generatedSyntax)}
          >
            Copy Syntax
          </button>
        </div>
      )}
    </div>
  );
};

export default SyntaxGenerator;
