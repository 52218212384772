// TradingViewLog.js
import React, { useEffect, useState } from 'react';
import api from '../api';
import { format } from 'date-fns';

const TradingViewLog = ({ token }) => {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const response = await api.get('/webhookops/logs', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setLogs(response.data);
      } catch (error) {
        console.error('Error fetching logs:', error);
      }
    };

    fetchLogs();
    const intervalId = setInterval(fetchLogs, 5000); // Fetch logs every 5 seconds

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [token]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900 text-white">
      <div className="w-full max-w-4xl p-4">
        <h1 className="text-2xl font-bold mb-4">TradingView Log</h1>
        <div className="bg-gray-800 p-4 rounded">
          {logs.length === 0 ? (
            <p>No logs available</p>
          ) : (
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-800 text-left text-xs font-semibold text-gray-300 uppercase tracking-wider">
                    Timestamp
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-800 text-left text-xs font-semibold text-gray-300 uppercase tracking-wider">
                    Message
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-800 text-left text-xs font-semibold text-gray-300 uppercase tracking-wider">
                    Exchange Response
                  </th>
                </tr>
              </thead>
              <tbody>
                {logs.slice(0).reverse().map((log) => (
                  <tr key={log.id}>
                    <td className="px-5 py-5 border-b border-gray-200 bg-gray-800 text-sm">
                      {format(new Date(log.timestamp), 'PPpp')}
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-gray-800 text-sm">
                      {log.message}
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-gray-800 text-sm">
                      {log.exchange_response}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default TradingViewLog;
