//WebhookDashboard.js
import React, { useEffect, useState } from 'react';
import api from '../api';
import SyntaxGenerator from './SyntaxGenerator';

const WebhookDashboard = ({ token }) => {
  const [exchange, setExchange] = useState(null);
  const [webhookUrl, setWebhookUrl] = useState('');

  useEffect(() => {
    const fetchWebhookUrl = async () => {
      try {
        const response = await api.get('/webhookops/list', {
          headers: { Authorization: `Bearer ${token}` }
        });

        if (response.data && response.data.length > 0) {
          const { url } = response.data[0];
          setWebhookUrl(url);
          localStorage.setItem('webhookUrl', url);
        } else {
          console.log('No webhook found');
        }
      } catch (error) {
        console.error('Error fetching webhooks:', error);
      }
    };

    fetchWebhookUrl();
  }, [token]);

  const handleExchangeSelect = (selectedExchange) => {
    setExchange(selectedExchange);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 text-white">
      <div className="w-full max-w-4xl p-4">
        <h1 className="text-2xl font-bold mb-4">Webhook URL</h1>
        <p className="mb-4">{webhookUrl || 'Fetching webhook URL...'}</p>
        <div className="flex flex-wrap -mx-2 mb-4">
          <div className="w-full px-2 mb-4">
            <h2 className="text-xl font-bold mb-2">Exchange</h2>
            <div className="flex space-x-2">
              <button
                className={`py-2 px-4 rounded ${exchange === 'bybit' ? 'bg-blue-700' : 'bg-blue-500 hover:bg-blue-700'}`}
                onClick={() => handleExchangeSelect('bybit')}
              >
                Bybit
              </button>
              {/* Add more buttons for other exchanges here */}
            </div>
          </div>
          {exchange && <SyntaxGenerator exchange={exchange} />}
        </div>
      </div>
    </div>
  );
};

export default WebhookDashboard;
