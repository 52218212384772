//LandingPage.js
import React from 'react';

const LandingPage = () => {
  return (
    <div className="bg-gray-900 text-white min-h-screen flex flex-col">
      <main className="flex-grow flex flex-col items-center justify-center p-4">
        <h1 className="text-4xl font-bold mb-4">Welcome to Automate Alerts to Bybit and Copy Trade</h1>
        <p className="text-lg text-center max-w-lg">Streamline your trading experience with automated alerts and copy trading. Effortlessly manage your trades and improve your strategies with our comprehensive platform.</p>
      </main>
    </div>
  );
};

export default LandingPage;
